.client-search {
    position: relative;
    display: flex;
    padding: 8em 5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h2 {
        font-size: 2.5rem;
    }

    & > p {
        margin: 3em;
        color: #4A4A4A;
        text-align: center;
        line-height: 2em;
    }

    & > form {
        width: 60%;

        & > .client-search-name {
            position: relative;
            margin: 3em auto;

            & > .client-search-name-autocomplete > ul.typeahead-selector {
                position: absolute;
                width: 100%;
                background-color: #fff;
                border: 2px solid #DADADA;

                & > li  {
                    list-style: none;
                    padding: 1em;
                    font-size: 1.2em;

                    &:not(:last-child) {
                        border-bottom: 1px solid #DADADA;
                    }

                    &.hover {
                        color: #fff;
                        background-color: #CD435F;

                        & > button { 
                            font-weight: bold !important;
                        }
                    }
                }
            }

            input {
                width: 100%;
                border: 1px solid #4A4A4A;
                font-size: 1rem;

                &:focus { outline: none; }
            }

            & > .client-search-name-validation {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 1em;

                &:before {
                    content: '✓';
                    color: #CD435F;
                    font-size: 2em;
                    font-weight: bold;
                }
            }

            & > button {
                position: absolute;
                top: 0;
                right: -1em;
                padding: 0;
                font-size: 44px;
                line-height: 44px;
                opacity: 0.6;
            }
        }
    }

}
