.product-list {
  flex: 1 1;
  height: 91.5vh;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5) inset;
  overflow-y: auto;

  table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
  }

  th {
    padding-top: 5em;
    position: -webkit-sticky !important;
    position: sticky;
    top: 1em;
    position: sticky;
    background: #ffffff;
  }

  th,
  td {
    padding: 1em;
    border-bottom: 1px solid #cd435f;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  h3 {
    text-align: center;
    margin: 1em;
  }

  .product-last-name,
  .product-first-name {
    text-align: left;
  }

  .product-credit {
    text-align: left;
    color: #79bf3c;
  }

  .product-nick-name {
    text-align: left;
    color: #26a1ce;
  }

  .product-section {
    text-align: left;
    color: #ed6e35;
  }
}
