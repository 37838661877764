.delivery {
    padding: 1em;
    $borderColor: darkgray;

    & > .delivery-last-update {
        color: $borderColor;
    }

    & > .delivery-pagination {
        margin: 2em 0;
        justify-content: center;

        & > button {
            width: 33.33%;
        }
    }

    & > .delivery-order-items {
        padding: 1em 0;

        & > table {
            width: 100%;
            border-collapse: collapse;

            th, td {
                border: 1px solid $borderColor;
                padding: 0.5em 1em;
            }

            & > thead > tr > th {
                text-align: left;

                &:nth-child(1) {
                    width: 5%;
                }

                &:nth-child(2), &:nth-child(3){
                    width: 10%;
                }

                &.header-actions {
                    width: 20%;
                }
            }

            & > tbody > tr > td.delivery-order-item-actions > button {
                font-size: 2rem;
                padding: 0 0.5em;
                
                &:first-child { color: green; }
                &:last-child { color: red; }
            }
        }
    }

}
