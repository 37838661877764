.modal-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 90%;
    min-height: 50vh;
    padding: 2em;
    background-color: #fff;
    border-radius: 6px;

    & > header {
      position: relative;

      & > h3 {
        font-weight: 200;
        font-size: 2em;
      }

      & > button.modal-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        font-size: 2em;
      }
    }
  }
}
