$navHeight: 5em;

nav.dashboard-menu {
    height: $navHeight;
    background-color: #CD435F;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;

    & > a, button {
        color: #fff;
        flex: 1;
        padding: 1em 0;
        font-size: 0.8rem;
        text-align: center;

        &.active {
            background-color: rgba(0, 0, 0, 0.25);    
        }

        & > img {
            display: block;
            margin: 0.5em auto;
            height: 2em;
        }

        &:focus {
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

.dashboard-content {
    display: flex;
    background-color: #fafafa;

    & > main {
        flex: 1 65%;
        height: calc(100vh - #{$navHeight});
        overflow-y: auto;
    }

    & > aside {
        flex: 1 35%;
        height: calc(100vh - #{$navHeight});
    }
}
