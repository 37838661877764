.login-container {
    background-color: #624538;
    background-image: url('/assets/wood.png');
    background-repeat: no-repeat;
    background-size: cover;

    & > .login-form {
        position: relative;
        background-image: url('/assets/board.png');
        background-repeat: no-repeat;
        background-size: cover;
    
        width: 90%;
        margin: auto;
        height: 100vh;
    
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:before, &:after {
            filter: brightness(400%);
        }
    
        & > form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    
            padding: 8em;
    
            background-image: url('/assets/fieldset-decoration.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
    
            & > img {
                position: absolute;
                width: 10em;
                transform: translateY(-15em);
            }
    
            & > input {
                width: 18em;
                font-size: 1em;
                margin: 1em 0;
            } 
    
            & > button[type=submit] {
                margin: 1em 0;
                max-width: 18em;
                transform: translateY(3em);
            }
        }
    }
}
