.user-main {
  display: flex;
  flex-wrap: wrap;
}

.user-search-list {
    display: flex;
    justify-content: center;
    padding: 1em;

    & > input {
        background-color: transparent;
        border-bottom: 1px solid #4a4a4a;
        text-align: left;
        padding: 0.5em 0;
        color: #4a4a4a;
        margin: 0.15em;
        width: 80%;
        font-size: 1rem;

        &:focus {
            outline: none;
        }

      }

}
