.order-selection {

    & > nav {
        display: flex;
        flex-direction: row;
        margin: 0.5em;

        & > .order-selection-nav-item {
            margin: 0.5em;
            padding: 0.5em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: calc(33% - 1em);
            border-top: 4px solid transparent;

            background-color: #fff;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.2);

            & > img {
                width: 2rem;
                margin-bottom: 0.5em;
            }

            & > span {
                font: Bold 20px/30px Lato;
                text-transform: capitalize;
            }

            &.current {
                transform: scale(1.1);
            }
        }
    }

    & > .order-selection-choice {
        margin: 0.5em;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;

        & > .order-selection-choice-item {
            position: relative;
            margin: 0.5em;
            width: calc(33% - 1em);
            height: 12em;
            background-color: rgba(0, 0, 0, 0.8);
            background-repeat: no-repeat;
            background-size: 140%;
            background-position: center;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);

            & > .order-selection-choice-item-info {                
                & > span {
                    color: #fff;
                    padding: 0.5em;
                    text-align: center;

                    &.order-selection-choice-item-stock {
                        float: left;
                        background-color: #CD435F;
                        border-bottom-right-radius: 6px;
    
                        &.empty {
                            background-color: #555555;
                        }
                    }
    
                    &.order-selection-choice-item-price {
                        float: right;
                        background-color: #26A1CE;
                        border-bottom-left-radius: 6px;
                    }
                }
            }
            
            & > .order-selection-choice-item-action {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 1em;
                width: 100%;
                text-align: center;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.8);
                height: 33.33%;
            }
        }
    }

}
