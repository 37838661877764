.product-form {
  flex: 1 1;

  h3 {
    margin: 1em;
  }

  .product-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    color: #4a4a4a;
    margin: 0.15em;
    padding: 1em;

    & > input {
      background-color: transparent;
      border-bottom: 1px solid #4a4a4a;
      text-align: left;
      color: #4a4a4a;
      margin: 0.15em;
      width: 80%;
      font-size: 1rem;
      margin: 0;
      padding: 0 0.5em;
      &:focus {
        outline: none;
      }
    }

    & > select,
    option {
      border-radius: 0;
      background-color: transparent;
      border: none;
      border: 1px solid #4a4a4a;
      width: 65%;
      text-indent: 0em;
      text-size-adjust: 1rem;
      color: #4a4a4a;
      margin: 0 1.9em;
      color: #4a4a4a;

      & > option {
        padding: 10em;
        margin: 2em;
      }
    }

    & > button {
      border: 1px solid #b03951;
      color: #ffffff;
      border-radius: 4px;
      background: #cd435f 0% 0% no-repeat padding-box;
      margin: 0.5em;
    }

    & > button:hover {
      background-color: #b53b54;
    }

    & > button:active {
      background-color: #b53b54;
      transform: translateY(1px);
    }

    & > .disabled {
      border: 1px solid grey;
      background: grey 0% 0% no-repeat padding-box;
    }

    & > i {
      font-size: 0.8em;
      color: #b03951;
    }
    & > label {
      font-size: 1rem;
      font-weight: bold;
    }

    & > li {
      width: 50%;
    }
  }

  .column {
    display: flex;
    flex: wrap;
    flex-direction: row;
    justify-content: left;

    & > span {
      padding: 1.2em;
    }

    & > button:hover {
      background-color: #f5f5f5;
    }
  }

  .product-form-button-new {
    border: 1px solid #b03951;
    color: #ffffff;
    border-radius: 4px;
    margin: 4em;
    position: fixed;
    background: #cd435f 0% 0% no-repeat padding-box;
    bottom: 0;
    right: 0;
  }

  .product-form-button-new:hover {
    background-color: #b53b54;
  }

  .product-form-button-new:active {
    transform: translateY(1px);
    background-color: #b53b54;
  }

  img {
    width: 10%;
  }

  .column-ingredients {
    width: 70%;
  }

  .column-ingredients-selector {
    width: 30%;
  }
}
