.chart-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 1em;
  font-weight: bold;

  & > .nav-button {
    display: flex;
    flex-direction: row;
    padding: 0em;

    & > button {
      margin: 0.2em;
    }
  }
}

.select-chart-type-button {
  display: flex;
  flex-direction: column;
  position: static;
  top: 0;
  left: 0;
  margin: 1em 1em;

  & > .chart-type-button {
    width: 3em;
    padding: 0.5em 0.5em;
  }
}

.chart-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;

  & > .chart-item {
    flex: 1 1 40%;
    margin: 1em 1em;
    box-shadow: -1px 0 3px rgba(0, 0, 0, 0.3);

    & > .chart {
      margin: 0 0 4em 4em;
    }

    & > .nav-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1em;

      & > .trash-button {
        margin-left: 1em;
      }

      & > button {
        margin: 0.2em;
      }

      & > .select-chart-type-button {
        display: flex;
        flex-direction: column;
        position: static;
        top: 0;
        left: 0;
        margin: 1em 1em 0 0;

        & > .chart-type-button {
          width: 3em;
          padding: 0.5em 0.5em;
        }
      }
    }
  }
}
