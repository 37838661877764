.account-form {
  flex: 1 85;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  h3 {
    margin: 1em;
  }

  .line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    color: #4a4a4a;
    margin: 0.15em;
    padding: 1em;

    & > input {
      background-color: transparent;
      border-bottom: 1px solid #4a4a4a;
      text-align: left;
      padding: 0;
      color: #4a4a4a;
      margin: 0.15em;
      width: 80%;
      font-size: 1rem;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    & > select,
    option {
      border-radius: 0;
      background-color: transparent;
      border: none;
      border: 1px solid #4a4a4a;
      width: 65%;
      text-indent: 0em;
      text-size-adjust: 1rem;
      color: #4a4a4a;
      margin: 0 1.9em;
      & > option {
        margin: 2em;
      }
    }

    & > button {
      border: 1px solid #b03951;
      color: #ffffff;
      border-radius: 4px;
      background: #cd435f 0% 0% no-repeat padding-box;
      margin: 0.5em;
    }
    & > i {
      font-size: 0.8em;
      color: #b03951;
    }
    & > label {
      font-weight: bold;
      text-align: center;
    }

    & > li {
      width: 50%;
    }
  }
}
