.user-list {
    flex: 1 1;
    height: 91.5vh;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5) inset;
    overflow-y: auto;  

  table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
  }

  th {
    padding-top: 5em;
    position: -webkit-sticky !important;
    position: sticky;
    top: 1em;
    position: sticky;
    background: #ffffff;
  }

  th,
  td {
    padding: 1em;
    border-bottom: 1px solid #cd435f;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  h3 {
    text-align: center;
    margin: 1em;
  }

  .user-credit {
    color: #79bf3c;
  }

  .user-nick-name {
    color: #26a1ce;
  }

  .user-section {
    color: #ed6e35;
  }
}
